export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/dashboard/Dashboard.vue'),
    },
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/users/Users.vue'),
        meta:{
            roles: ['admin']
        }
    },
    {
        path: '/objects',
        name: 'objects',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/objects/Objects.vue'),
    },
    {
        path: '/invalid',
        name: 'invalid',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/authentication/Invalid.vue'),
    },
    {
        path: '/objects/new',
        name: 'objects_new',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/objects/ObjectsCreate.vue'),
    },
    {
        path: '/objects/:id',
        name: 'objects_edit',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/objects/ObjectsEdit.vue'),
    },
    {
        path: '/renters',
        name: 'renters',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/renters/Renters.vue'),
    },
    {
        path: '/renters/new',
        name: 'renters_new',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/renters/RentersCreate.vue'),
    },
    {
        path: '/renters/:id',
        name: 'renters_edit',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/renters/RentersEdit.vue'),
        children:[
            {
                path: "",
                name:'r-account-holder',
                redirect: { name: "r_account" },
            },
            {
                path:'account',
                name:'r_account',
                component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/renters/RentersAccount.vue'),
            },
            {
                path:'detail',
                name:'r_detail',
                component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/renters/RentersDetail.vue'),
            },
            {
                path: "/:id/:catchAll(.*)",
                component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/404.vue')
            },
        ]
    },
    {
        path: '/agreements',
        name: 'agreements',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/agreements/Agreements.vue'),
    },
    {
        path: '/agreements/new',
        name: 'agreements_new',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/agreements/AgreementsCreate.vue'),
    },
    {
        path: '/agreements/:id',
        name: 'agreements_edit',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/agreements/AgreementsEdit.vue'),
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/tasks/Tasks.vue'),
    },
    {
        path: '/tasks/new',
        name: 'tasks_new',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/tasks/TasksCreate.vue'),
    },
    {
        path: '/tasks/:id',
        name: 'tasks_edit',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/tasks/TasksEdit.vue'),
    },
    {
        path: '/financial',
        name: 'financial',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/financial/Financial.vue'),
    },

    {
        path: '/settings/user_roles',
        name: 'user_roles',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/users/UsersRoles.vue'),
        meta:{
            roles: ['admin','manager']
        }
    },
    {
        path: '/settings/users/new',
        name: 'users_new',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/users/UsersCreate.vue'),
        meta:{
            roles: ['admin','manager']
        }
    },
    {
        path: '/settings/users/:id',
        name: 'user_edit',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/users/UsersEdit.vue'),
        children:[
            {
                path: "",
                name:'account-holder',
                redirect: { name: "account" },
            },
            {
                path:'account',
                name:'account',
                component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/users/UsersAccount.vue'),
            },
            {
                path:'security',
                name:'security',
                component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/users/UsersSecurity.vue'),
            },
            {
                path: "/:id/:catchAll(.*)",
                component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/404.vue')
            },
        ]
    },
    {
        path: '/settings/general',
        name: 'setting_general',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/settings/General.vue'),
        meta:{
            roles: ['admin','manager']
        }
    },
    {
        path: "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/404.vue')
    },
];
