import { defineStore } from "pinia";

export const useSessionAuth = defineStore("auth_session", {
  state: () => ({
    token: null,
    refresh_token: null,
    user: {},
  }),
  persist: {
    storage: window.sessionStorage,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    hasSession(state) {
      return !!state.token;
    },
    getUser(state) {
      return state.user;
    },
  },
  actions: {
    // any amount of arguments, return a promise or not
    setToken(token) {
      this.token = token;
    },
    setUser(user) {
      this.user = user;
    },
    setRefreshTokenSession(token) {
      this.refresh_token = token;
    },
  },
});
