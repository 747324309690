import {defineStore} from "pinia";
import {useSessionAuth} from "@/pinia/AuthSessionStore";

export const useLocalAuth = defineStore("auth_local", {
    state: () => ({
        refresh_token: null,
        user: {},
        selected_role: null,
    }),
    persist: {
        paths: ['refresh_token','selected_role'],
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getClients(state) {
            return state.user?.clients;
        },
        getRoles(state){
            return state?.user?.user_roles
        },
        isAdmin(state) {
            return state?.user?.user_roles.some((role) => role.role === 'admin')
        },
        isUser(state) {
            return state?.selected_role?.role === 'user';
        },
        isConsumer(state) {
            return state.selected_role?.tenant?.type === 'Consumer';
        },
        isBusiness(state) {
            return state.selected_role?.tenant?.type === 'Business';
        },
        hasExact(state) {
            return !!state.selected_role?.tenant?.hasExactOnline;
        },
        hasOPP(state) {
            return !!state.selected_role?.tenant?.opp_payment?.id;
        }
    },
    actions: {
        setRole(role) {
            this.selected_role = role;
        },
        updateRoleTenant(tenant){
            this.selected_role.tenant = tenant;
        },
        // any amount of arguments, return a promise or not
        setToken(token) {
            const {setTokenSession} = useSessionAuth();
            setTokenSession(token);
        },
        setRefreshToken(token) {
            this.refresh_token = token;
        },
        setUser(user) {
            this.user = user;
        },
        updateClient(client) {
            const clientIndex = this.user?.clients.findIndex((obj) => {
                return obj.id === client.id;
            });
            if (clientIndex) {
                console.log("user found");
                this.user.clients[clientIndex] = client;
            } else {
                console.log("no user found");
            }
        },
    },
});
