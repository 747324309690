export default [
    {
        path: '/admin/tenants',
        name: 'tenants',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/tenants/Tenants.vue'),
    },
    {
        path: '/admin/settings',
        name: 'admin_settings',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/tenants/Admin.vue'),
    },
    {
        path: '/admin/tenants/new',
        name: 'tenants_new',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/tenants/TenantsCreate.vue'),
    },
    {
        path: '/admin/tenants/:id',
        name: 'tenants_edit',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/tenants/TenantsEdit.vue'),
    },
    {
        path: "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/404.vue')
    },
];
