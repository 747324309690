import {createWebHistory, createRouter} from 'vue-router';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import ProtectedRoutes from './ProtectedRoutes.js';
import {useLocalAuth} from "@/pinia/AuthLocalStore.js";
import {useSessionAuth} from "@/pinia/AuthSessionStore.js";

const routes = [
    {
        name: 'Public',
        path: '/',
        component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
        children: PublicRoutes,
        meta: {anonymous: false},
    },
    {
        name: 'Admin',
        path: '/admin',
        component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
        children: PrivateRoutes,
        meta: {anonymous: false},
    },
    {
        name: 'Auth',
        path: '/auth',
        component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
        children: ProtectedRoutes,
        meta: {anonymous: true},
    },
    {
        path: '/change-role',
        name: 'change_role',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/ChangeRole.vue'),
    },
    {
        path: '/close',
        name: 'close',
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/authentication/Close.vue'),
    },
    {
        path: "/:catchAll(.*)",
        component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/404.vue')
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkExactActiveClass: 'active',
    routes,
});

router.beforeEach(async (to, from, next) => {
    const localAuth = useLocalAuth();
    const sessionAuth = useSessionAuth();
    if (to.name === 'login' || to.name === 'forgotPassword' || to.name == 'resetPassword' || to.name == 'close') {
        return next();
    }

    if (
        !sessionAuth.token &&
        !sessionAuth.refresh_token &&
        !localAuth.refresh_token &&
        !to.meta.auth
    ) {
        const redirect = to.fullPath;
        console.log('go', redirect)
        console.log('going to login')
        return next({name: "login", params: {redirect}});
    }


    if (
        to.name === "resetPassword" && !to.query?.token) {
        const redirect = to.fullPath;
        console.log('going to login')

        return next({name: "login", params: {redirect}});
    }
    if (!localAuth.selected_role && to.name !== 'change_role') {

        return next({name: "change_role"});
    }

    // console.log(to, store);
    if (
        (sessionAuth.token ||
            localAuth.refresh_token ||
            sessionAuth.refresh_token) &&
        to.meta.auth
    ) {
        console.log('going to dashboard')
        return next({name: "dashboard"});
    }

    if (to.meta.roles && !to.meta.roles.includes(localAuth.selected_role.role)) {
        console.log('going to dashboard')
        return next({name: "dashboard"});
    }


    next();
});

export default router;
