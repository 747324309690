const routes = [
  {
    path: "",
    name:'login-holder',
    redirect: { name: "login" },
  },
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignIn.vue'),
  },
  {
    path: 'register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/Signup.vue'),
  },
  {
    path: 'forgotPassword',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ForgotPassword.vue'),
  },
  {
    path: 'reset-password',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ResetPassword.vue'),
  },
];

export default routes;
